import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { Router, Route, browserHistory, IndexRedirect } from 'react-router'
import { syncHistoryWithStore, routerReducer } from 'react-router-redux'
import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';

import App from './components/App';
import RecipeList from './components/RecipeList';
import Recipe from './components/Recipe';
import NewRecipe from './components/NewRecipe';

import reducers from './reducers';
import { fetchRecipes } from './actions/recipes';
import './index.css';

let store = createStore(
  combineReducers({
    app: reducers,
    routing: routerReducer
  }),
  applyMiddleware(thunkMiddleware)
);

if (process.env.NODE_ENV === 'development') {
  const loggerMiddleware = createLogger();
  store = createStore(
    combineReducers({
      app: reducers,
      routing: routerReducer
    }),
    applyMiddleware(
      thunkMiddleware,
      loggerMiddleware
    )
  );
}

store.dispatch(fetchRecipes());

const history = syncHistoryWithStore(browserHistory, store)

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Route path="/" component={App}>
        <IndexRedirect to="/recipes" />
        <Route path="recipes" component={RecipeList}/>
        <Route path="recipes/new" component={NewRecipe}/>
        <Route path="recipes/:slug" component={Recipe}/>
      </Route>
    </Router>
  </Provider>,
  document.getElementById('root')
);
