import React, { Component } from 'react';
import './RecipeView.css';

class RecipeView extends Component {
  render() {
    const recipe = this.props.recipe || {};
    const ingredients = recipe.ingredients || [];
    const steps = recipe.steps || [];
    const shopping = this.props.shopping;
    return (
      <div className='RecipeView'>
        <div className='RecipeView-column'>
          { ingredients.length > 0 ?
          <h2>Ingredients</h2> : <h2>Ingredients will appear here</h2>
          }
          <ul>
            {ingredients.map((ingredient, index) =>
            <li
              className='RecipeView-li'
              key={`${ingredient}${index}`}
            >
              {ingredient}
              { shopping ? ` (${recipe.title})` : '' }
            </li>
            )}
          </ul>
        </div>
        <div className='RecipeView-column'>
          { steps.length > 0 ?
          <h2>Steps</h2> : <h2>Steps will appear here</h2>
          }
          <ol>
            {steps.map(step =>
            <li
              className='RecipeView-li'
              key={step}>
              {step}
            </li>
            )}
          </ol>
        </div>
      </div>
    )
  }
}

export default RecipeView;
