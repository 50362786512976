import React, { Component } from 'react';
import TopBar from '../TopBar';
import Icon from '../Icon';
import RecipeView from '../RecipeView';
import './Recipe.css';

const jsonEditURL = 'https://github.com/kmcq/meals/edit/master/src/static/meals.json';

class Recipe extends Component {
  renderJSONViewer() {
    if (!this.props.isInCode) {
      const recipe = this.props.recipe || {};
      const JSONString = JSON.stringify({[recipe.slug]: recipe}, null, 2);
      return (
        <div className='Recipe-JSONView'>
          <div className='Recipe-JSONView-warning'>
            It looks like this recipe as it exists in your system doesn't match
            the master copy. Please&nbsp;
            <b>
              <a href={jsonEditURL} target='newTab'>
                commit this to Github
              </a>
            </b>
            &nbsp;to add your changes or&nbsp;
            <b onClick={() => this.props.resetLocalVersion(recipe.slug)}>
              click here to wipe away your local changes
            </b>
            .
          </div>
          <pre className='Recipe-JSONView-pre'>
            <code>
              {JSONString}
            </code>
          </pre>
        </div>
      );
    }
    return null;
  }

  render() {
    const recipe = this.props.recipe || {};
    return (
      <div>
        <TopBar
          title={recipe.title}
          leftNav={{
            icon: <Icon type='home' />,
            label: 'HOME',
            path: '/'
          }}
          rightNav={{
            icon: <Icon type='edit' />,
            label: 'EDIT',
            path: '/recipes/new',
            action: () => { this.props.editRecipe(recipe) }
          }}
        />
        <div className='Recipe'>
          <RecipeView recipe={recipe} shopping={this.props.shopping} />
        </div>
        {this.renderJSONViewer()}
      </div>
    )
  }
}

export default Recipe;
