import codeRecipes from '../static/meals.json';
const RECIPES_KEY = 'MEALS_RECIPES_KEY';

export const RECEIVE_RECIPES = 'RECEIVE_RECIPES';
function receiveRecipes(recipes, key='recipes') {
  return {
    key,
    recipes,
    type: RECEIVE_RECIPES
  }
}

export const fetchRecipes = () => {
  return dispatch => {
    let recipesForState = codeRecipes; // From static/meals.json
    try {
      const storageRecipes = JSON.parse(localStorage.getItem(RECIPES_KEY));
      if (storageRecipes) {
        recipesForState = { ...recipesForState, ...storageRecipes };
      } else {
        localStorage.setItem(RECIPES_KEY, JSON.stringify(codeRecipes))
      }
    } catch(err) {
      console.error(err);
      console.warn('Not loading badly formatted recipes JSON.');
      console.warn('Reformatting local storage recipes.');
      localStorage.setItem(RECIPES_KEY, JSON.stringify(codeRecipes))
    }
    dispatch(receiveRecipes(codeRecipes, 'codeRecipes'));
    dispatch(receiveRecipes(recipesForState));
  }
}

export const FILTER_RECIPES = 'FILTER_RECIPES';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const searchForRecipe = (searchTerm) => {
  return (dispatch, getState) => {
    let searchedForRecipes = {};
    const recipes = getState().app.recipes;
    Object.keys(recipes).forEach(slug => {
      const recipe = recipes[slug];
      const recipeAsText = JSON.stringify(recipe);
      const searchRegex = new RegExp(searchTerm, 'i');
      if (recipeAsText.match(searchRegex)) {
        searchedForRecipes = {
          ...searchedForRecipes,
          [slug]: recipe
        };
      }
    });

    dispatch(filterRecipes(searchedForRecipes))
    dispatch({ searchTerm, type: SET_SEARCH_TERM })
  }
}

function filterRecipes(searchedForRecipes) {
  return {
    type: FILTER_RECIPES,
    recipes: searchedForRecipes
  }
}

export const EDIT_RECIPE = 'EDIT_RECIPE';
export const editRecipe = (recipe) => {
  return {
    recipe,
    type: EDIT_RECIPE
  }
}

export const UPDATE_RECIPE = 'UPDATE_RECIPE';
export const updateRecipe = (field, update) => {
  return {
    field,
    type: UPDATE_RECIPE,
    update: field === 'title' ? update : update.split('\n')
  }
}

function slugify(text) {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text
}

export const SAVE_RECIPE = 'SAVE_RECIPE';
export const saveRecipe = () => {
  return (dispatch, getState) => {
    const appState = getState().app;
    let newRecipe = appState.newRecipe;

    if (newRecipe) {
      newRecipe.slug = slugify(newRecipe.title);
      const recipes = {
        ...appState.recipes,
        [newRecipe.slug]: newRecipe
      };

      localStorage.setItem(RECIPES_KEY, JSON.stringify(recipes))

      dispatch({ recipes, type: SAVE_RECIPE });
    }
  }
}

export const resetLocalVersion = (recipeSlug) => {
  return (dispatch, getState) => {
    let resetRecipes = getState().app.recipes;
    if (codeRecipes[recipeSlug]) {
      resetRecipes[recipeSlug] = codeRecipes[recipeSlug];
    } else {
      delete resetRecipes[recipeSlug];
    }

    localStorage.setItem(RECIPES_KEY, JSON.stringify(resetRecipes));

    dispatch(receiveRecipes(resetRecipes));
  }
}

export const TOGGLE_SHOPPING = 'TOGGLE_SHOPPING';
export const toggleShopping = () => {
  return { type: TOGGLE_SHOPPING }
}
