import { connect } from 'react-redux';
import RecipeList from './RecipeList';
import { searchForRecipe, toggleShopping } from '../../actions/recipes';

const mapStateToProps = (state) => {
  if (state.app) {
    const recipes = state.app.filteredRecipes || state.app.recipes;
    const codeRecipes = state.app.codeRecipes;
    return {
      codeRecipes,
      recipes,
      searchTerm: state.app.searchTerm || '',
      shopping: state.app.shopping,
    }
  } else {
    return {}
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    searchForRecipe: (searchTerm) => {
      dispatch(searchForRecipe(searchTerm))
    },
    toggleShopping: () => {
      dispatch(toggleShopping())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipeList);
