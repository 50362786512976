import React, { Component } from 'react';
import Link from 'react-router/lib/Link';
import './TopBar.css';

class TopBar extends Component {
  renderInnerNav(props) {
    return (
      <div>
        <div className='TopBar-navIcon'>
          {props.icon}
        </div>
        <div>
          {props.label}
        </div>
      </div>
    );
  }

  renderLinkNav(props, children=null) {
    return (
      <Link to={props.path}>
        {children || this.renderInnerNav(props)}
      </Link>
    );
  }

  renderActionNav(props) {
    return (
      <div onClick={props.action}>
        {this.renderInnerNav(props)}
      </div>
    );
  }

  renderNav(props) {
    if (props) {
      if (props.path) {
        if (props.action) {
          return this.renderLinkNav(props, this.renderActionNav(props));
        }
        return this.renderLinkNav(props);
      } else if (props.action) {
        return this.renderActionNav(props);
      }
    }
    return null;
  }

  render() {
    const { title, leftNav, rightNav } = this.props;
    return (
      <div className='TopBar'>
        <div className='TopBar-nav TopBar-leftNav'>
          {this.renderNav(leftNav)}
        </div>
        <h1 className='TopBar-title'>
          {title || 'MEALS'}
        </h1>
        <div className='TopBar-nav TopBar-rightNav'>
          {this.renderNav(rightNav)}
        </div>
      </div>
    )
  }
}

export default TopBar;
