import React from 'react';
import './Inputs.css';

function prepArrayInput(data) {
  return data instanceof Array ? data.join('\n') : data;
}

const Inputs = (props) => (
  <div className='Inputs'>
    <input
      className='input'
      type='text'
      placeholder='Title'
      value={props.title}
      onChange={(e) => props.onChange('title', e.target.value)}
    />
    <textarea
      className='input'
      placeholder='Ingredients. Separate with returns'
      value={prepArrayInput(props.ingredients)}
      onChange={(e) => props.onChange('ingredients', e.target.value)}
    />
    <textarea
      className='input'
      placeholder='Steps. Separate with returns'
      value={prepArrayInput(props.steps)}
      onChange={(e) => props.onChange('steps', e.target.value)}
    />
  </div>
);

export default Inputs;
