import {
  RECEIVE_RECIPES,
  FILTER_RECIPES,
  SET_SEARCH_TERM,
  UPDATE_RECIPE,
  SAVE_RECIPE,
  EDIT_RECIPE,
  TOGGLE_SHOPPING,
} from '../actions/recipes';

export default (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_RECIPES:
      return { ...state, [action.key]: action.recipes };
    case FILTER_RECIPES:
      return { ...state, filteredRecipes: action.recipes };
    case SET_SEARCH_TERM:
      return { ...state, searchTerm: action.searchTerm };
    case EDIT_RECIPE:
      return { ...state, newRecipe: action.recipe };
    case UPDATE_RECIPE:
      const update = { [action.field]: action.update };
      return { ...state, newRecipe: { ...state.newRecipe, ...update }};
    case SAVE_RECIPE:
      return { ...state, recipes: action.recipes, newRecipe: {} };
    case TOGGLE_SHOPPING:
      return { ...state, shopping: !state.shopping };
    default:
      return state;
  }
}
