import React from 'react';
import TopBar from '../TopBar';
import RecipeView from '../RecipeView';
import Icon from '../Icon';
import Inputs from './Inputs';
import './NewRecipe.css';

class NewRecipe extends React.Component {
  render() {
    return (
      <div>
        <TopBar
          title={this.props.recipe.title || 'New Recipe'}
          leftNav={{
            icon: <Icon type='home' />,
            label: 'HOME',
            path: '/'
          }}
          rightNav={{
            icon: <Icon type='save' />,
            label: 'SAVE',
            path: '/',
            action: this.props.saveRecipe
          }}
        />
        <div className='NewRecipe'>
          <Inputs
            onChange={this.props.updateRecipe}
            {...this.props.recipe}
          />
          <RecipeView recipe={this.props.recipe} />
        </div>
      </div>
    );
  }
}

export default NewRecipe;
