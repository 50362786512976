import { connect } from 'react-redux';
import NewRecipe from './NewRecipe';
import { updateRecipe, saveRecipe } from '../../actions/recipes';

const mapStateToProps = (state) => {
  const recipe = state.app.newRecipe || {};
  return {
    recipe
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateRecipe(field, update) {
      dispatch(updateRecipe(field, update))
    },
    saveRecipe() {
      dispatch(saveRecipe())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewRecipe);
