import { connect } from 'react-redux';
import Recipe from './Recipe';
import { editRecipe, resetLocalVersion } from '../../actions/recipes';

const mapStateToProps = (state, ownProps) => {
  const paramsSlug = ownProps.params.slug;
  const recipe = state.app.recipes[paramsSlug];
  const isInCode = JSON.stringify(state.app.codeRecipes[paramsSlug]) === JSON.stringify(recipe);
  return {
    recipe,
    isInCode,
    shopping: state.app.shopping,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    resetLocalVersion: (slug) => {
      dispatch(resetLocalVersion(slug))
    },
    editRecipe(recipe) {
      dispatch(editRecipe(recipe))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Recipe);
