import React, { Component } from 'react';
import RecipeListItem from './RecipeListItem';
import TopBar from '../TopBar';
import Icon from '../Icon';
import Loading from '../Loading';
import './RecipeList.css';

class RecipeList extends Component {
  search(term) {
    this.props.searchForRecipe(term);
  }

  codeRecipeDiffers(recipe) {
    const codeRecipe = this.props.codeRecipes && this.props.codeRecipes[recipe.slug];
    return JSON.stringify(codeRecipe) !== JSON.stringify(recipe);
  }

  render() {
    if (!this.props.recipes) {
      return <Loading />;
    }
    const recipes = this.props.recipes || {};
    return (
      <div>
        <TopBar
          title='Recipes'
          leftNav={{
            icon: <Icon type='add' />,
            label: 'NEW',
            path: '/recipes/new'
          }}
          rightNav={{
            icon: <Icon type={this.props.shopping ? 'remove_shopping_cart' : 'shopping_cart'} />,
            label: this.props.shopping ? 'BROWSE' : 'SHOP',
            action: () => { this.props.toggleShopping() }
          }}
        />
        <div className='RecipeList'>
          <input
            autoFocus
            className='RecipeList-searchInput'
            type='search'
            placeholder='Find a recipe by name or ingredient'
            onChange={(e) => this.search(e.target.value)}
            value={this.props.searchTerm}
          />
          {Object.keys(recipes).sort((a, b) => a.localeCompare(b)).map(slug =>
            <RecipeListItem
              key={slug}
              codeRecipeDiffers={this.codeRecipeDiffers(recipes[slug])}
              {...recipes[slug]}
            />
          )}
        </div>
      </div>
    );
  }
}

export default RecipeList;
