import React, { Component } from 'react';
import Link from 'react-router/lib/Link';
import Icon from '../Icon';
import './RecipeListItem.css';

class RecipeListItem extends Component {
  numOfText(arr, name) {
    return arr ? `${arr.length} ${name}` : null;
  }

  renderWarning() {
    return (
      <div
        title='This recipe needs to be added to the codebase. Talk with Keith.'
        className='RecipeListItem-warning'
      >
        <Icon type='warning' />
      </div>
    );
  }

  render() {
    const { ingredients, steps, slug, title } = this.props;

    return (
      <Link to={`recipes/${slug}`} className='RecipeListItem'>
        <div className='RecipeListItem-text'>
          <h2 className='RecipeListItem-title'>
            {title}
          </h2>
          <div>
            {
              [
                this.numOfText(ingredients, 'ingredients'),
                this.numOfText(steps, 'steps')
              ].join(' and ')
            }
          </div>
        </div>
        <div className='RecipeListItem-right'>
          {this.props.codeRecipeDiffers ? this.renderWarning() : null}
        </div>
      </Link>
    )
  }
}

export default RecipeListItem;
